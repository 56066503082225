import React from "react";
import Wrap from "../components/wrap";
import SEO from "../components/seo";

import LocalizedLink from "../components/localizedLink";
import { graphql } from "gatsby";
import { v4 } from "uuid";

import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import MdxLink from "../components/mdxLink";
import Image from "gatsby-image";
import Background from "../images/monkey/flesje.png";
import { useTranslation } from "react-i18next";

function IndexPage({
  data: { mdx, intro, goal, about, bailey, baileyImage, what, projects },
}) {
  const { t } = useTranslation();
  const heroBg = {
    backgroundImage: `url(${Background})`,
  };

  return (
    <>
      <SEO
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description}
        keywords={mdx.frontmatter.keywords}
      />

      <section style={heroBg} className="bg-cover bg-center bg-jungle-400">
        <Wrap>
          <MDXProvider
            components={{
              h1: (props) => (
                <h1 {...props} className="c-h1 max-w-lg text-white" />
              ),
              h2: (props) => (
                <h2 {...props} className="c-h3 text-jungle-200 c-font-hand" />
              ),
              a: (props) => (
                <LocalizedLink
                  {...props}
                  to="/about"
                  className="c-button c-button--white"
                />
              ),
            }}
          >
            <MDXRenderer>{intro.edges[0].node.childMdx.body}</MDXRenderer>
          </MDXProvider>
        </Wrap>
      </section>

      <div className="bg-jungle-300 bg-leaf-2 bg-edge bg-edge--te-300 text-white">
        <Wrap>
          <div className="md:grid gap-12 grid-cols-12">
            <div className="col-span-7">
              <MDXProvider
                components={{
                  h1: (props) => <h1 {...props} className="c-h1" />,
                  h2: (props) => (
                    <h2
                      {...props}
                      className="c-h3 text-jungle-200 c-font-hand"
                    />
                  ),
                  p: (props) => <p {...props} className="c-p-lg" />,
                  a: (props) => (
                    <MdxLink {...props} className="c-button c-button--white" />
                  ),
                }}
              >
                <MDXRenderer>{goal.edges[0].node.childMdx.body}</MDXRenderer>
              </MDXProvider>
            </div>
            <div className="col-span-5 c-about bg-jungle-400 p-8 rounded self-start">
              <MDXProvider
                components={{
                  h2: (props) => (
                    <h2
                      {...props}
                      className="text-xl font-bold leading-none mb-3"
                    />
                  ),
                  p: (props) => (
                    <p {...props} className="text-base leading-normal mb-6" />
                  ),
                  a: (props) => <MdxLink {...props} className="text-white" />,
                  ul: (props) => (
                    <ul {...props} className="list-outside list-disc ml-5" />
                  ),
                  hr: (props) => <hr {...props} className="my-6 opacity-25" />,
                }}
              >
                <MDXRenderer>{about.edges[0].node.childMdx.body}</MDXRenderer>
              </MDXProvider>
            </div>
          </div>
        </Wrap>
      </div>
      <div className="bg-jungle-100 bg-edge bg-edge--be-100">
        <Wrap className="sm:grid grid-cols-12 bg-leaf-3">
          <div className="col-span-4 sm:pr-8">
            <Image
              fluid={baileyImage.childImageSharp.fluid}
              className="w-full mb-8 md:mb-0 md:mr-4 rounded"
              alt={bailey.frontmatter.title}
            />
          </div>
          <div className="col-span-8 self-center">
            <h1 className="c-h2">{bailey.frontmatter.title}</h1>
            <p className="c-p-lg">{bailey.frontmatter.intro}</p>
            <LocalizedLink
              className="c-button"
              to={`/${bailey.parent.relativeDirectory}`}
            >
              {t("btn.baily")}
            </LocalizedLink>
          </div>
        </Wrap>
      </div>

      <hr />

      <Wrap className="md:grid md:bg-leaf-4 grid-cols-12 gap-12">
        <header className="col-span-8">
          <MDXProvider
            components={{
              h1: (props) => <h2 {...props} className="c-h1" />,
              h2: (props) => (
                <h2 {...props} className="c-h4 c-font-hand text-jungle-200" />
              ),
              p: (props) => <p {...props} className="c-p-lg" />,
            }}
          >
            <MDXRenderer>{what.edges[0].node.childMdx.body}</MDXRenderer>
          </MDXProvider>
        </header>

        {projects.edges.map((item) => {
          return (
            <div
              key={v4()}
              className="col-span-12 md:col-span-6 border-l-2 border-jungle-100 pl-6 self-start"
            >
              <h2 className="c-h3">{item.node.frontmatter.title}</h2>
              <p className="c-p mb-2">{item.node.frontmatter.intro}</p>
              <p className="c-p">
                <LocalizedLink to={`/${item.node.parent.relativeDirectory}`}>
                  {t("btn.readMore")}
                </LocalizedLink>
              </p>
            </div>
          );
        })}
      </Wrap>
    </>
  );
}

export default IndexPage;

export const query = graphql`
  query IndexPageQuery($locale: String!) {
    mdx: mdx(
      frontmatter: { templateKey: { eq: "home" } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        description
        keywords
      }
    }
    intro: allFile(
      filter: {
        sourceInstanceName: { eq: "mdx-blocks" }
        relativeDirectory: { eq: "home/intro" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
    ) {
      edges {
        node {
          childMdx {
            body
          }
        }
      }
    }

    goal: allFile(
      filter: {
        sourceInstanceName: { eq: "mdx-blocks" }
        relativeDirectory: { eq: "home/goal" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
    ) {
      edges {
        node {
          childMdx {
            body
          }
        }
      }
    }

    about: allFile(
      filter: {
        sourceInstanceName: { eq: "mdx-blocks" }
        relativeDirectory: { eq: "home/about" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
    ) {
      edges {
        node {
          childMdx {
            body
          }
        }
      }
    }

    bailey: mdx(
      frontmatter: { imagePath: { eq: "bg/dog.jpg" } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        intro
      }
      parent {
        ... on File {
          relativeDirectory
        }
      }
    }

    baileyImage: file(relativePath: { eq: "dogs/bailey.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 280) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    what: allFile(
      filter: {
        sourceInstanceName: { eq: "mdx-blocks" }
        relativeDirectory: { eq: "home/what" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
    ) {
      edges {
        node {
          childMdx {
            body
          }
        }
      }
    }

    projects: allMdx(
      filter: {
        frontmatter: { templateKey: { eq: "project" } }
        fields: { locale: { eq: $locale } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            intro
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
        }
      }
    }
  }
`;
